import Modal from 'react-bootstrap/Modal';
import { BASE_URI } from '../../../assets/app.config';
import axios from 'axios';
import configHeader from '../../../assets/utils/helperConfigHeader';
import { helperToast } from '../../../assets/utils/helperToast';
import { helperUriParser } from '../../../assets/utils/helperUri';
import { useState } from 'react';

const ModalDeleteFile = ({ showModal, setShowModal, file }) => {
  const id = helperUriParser();
  const [loading, setLoading] = useState(false);

  const handlePullFile = () => {
    setLoading(true);
    axios
      .put(
        `${BASE_URI}folder-pull-file/${id}`,
        {
          id: file._id,
          fileName: file.fileName,
        },
        configHeader()
      )
      .then((_) => {
        setLoading(false);
        window.location.reload();
        helperToast(true, 'Fichier supprimé !');
      })
      .catch((_) => {
        setLoading(false);
        helperToast(false, "Une erreur s'est produite !");
      });
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      keyboard={false}
      centered
      size='200'
    >
      <Modal.Header>
        <span className='selectedFolder--modalTitle'>
          EFFACER {` "${file?.name}"`}
        </span>{' '}
      </Modal.Header>
      <Modal.Body>
        <span className='selectedFolder--modaltext'>
          Êtes vous sur de vouloir effacer ?
        </span>
        <div className='selectedFolder--btns-modal'>
          <button
            disabled={loading}
            className='selectedFolder--btn-annuler'
            onClick={() => setShowModal(false)}
          >
            Annuler
          </button>
          <button
            disabled={loading}
            onClick={handlePullFile}
            className='selectedFolder--btn-delete'
          >
            {loading ? 'Effacer...' : 'Effacer'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDeleteFile;
