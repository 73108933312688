import { Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import SelectedFolder from "./SelectedFolder";
import Management from "./Management";
import Informations from "./Informations";
import AssignFolder from "./AssignFolder";

const Online = () => {
  return (
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Route exact path="/selected/:slug" component={SelectedFolder} />
      <Route path="/management" component={Management} />
      <Route path="/informations" component={Informations} />
      <Route path="/assign-folder/:slug" component={AssignFolder} />
    </Switch>
  );
};

export default Online;
