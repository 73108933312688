export const helperAtobToken = (index) => {
  const res = JSON.parse(
    window.atob(sessionStorage.getItem("transferLux-token").split(".")[1])
  );
  if (index) {
    return res[index];
  }
  return res;
};

export const helperDecriptToken = (token) => {
  const res = JSON.parse(window?.atob(token.split(".")[1]));
  return res;
};
