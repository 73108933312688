import { troncString } from '../../../assets/utils/troncString';
import PdfIcon from '../../../assets/images/fichier-pdf.png';
import CsvIcon from '../../../assets/images/fichier-csv.png';
import DbfIcon from '../../../assets/images/dbf.png';
import ZipIcon from '../../../assets/images/format-de-fichier-zip.png';
import FileIcon from '../../../assets/images/fichier.png';

export const File = ({ file, handleDelete }) => {
  const type = file.type?.split('/')[1] || file.type;
  const primaryTypes = ['pdf', 'csv', 'dbf', 'x-zip-compressed'];

  const formatFile =
    type === 'pdf'
      ? PdfIcon
      : type === 'csv'
      ? CsvIcon
      : type === 'dbf'
      ? DbfIcon
      : type === 'x-zip-compressed'
      ? ZipIcon
      : file.type.startsWith('image/')
      ? URL.createObjectURL(file)
      : !primaryTypes.includes(type) &&
        !file.type.startsWith('image/') &&
        FileIcon;

  return (
    <div className='fileView'>
      <img
        className='fileView--img'
        src={formatFile}
        alt='upload'
        style={{ width: 80, height: 80 }}
      />
      <p className='fileView--name'>{troncString(file?.name, 25)}</p>
      <button className='fileView--deleteBtn' onClick={handleDelete}>
        X
      </button>
    </div>
  );
};
