import axios from "axios";
import { BASE_URI } from "../app.config";
import configHeader from "../utils/helperConfigHeader";

export const addFolder = (body) => {
  return axios
    .post(`${BASE_URI}folder/add`, body,configHeader())
    .catch((err) => console.log(err));
};

export const getFolders = (token) => {
  return axios.get(`${BASE_URI}folders/${token}`,configHeader());
};

export const getFolderById = (id) => {
  return axios.get(`${BASE_URI}folder/${id}`,configHeader());
};

export const findFolder = (search)=>{
  return axios.get(`${BASE_URI}folders/search/${search}`,configHeader())
}

export const findFolderFile = (id,search)=>{
  return axios.get(`${BASE_URI}folders/file/${id}?search=${search}`,configHeader())
}

export const deleteFolder = (id)=>{
  return axios.delete(`${BASE_URI}folder/${id}`,configHeader())
}

