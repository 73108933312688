import axios from "axios";
import { useState } from "react";
import { BASE_URI } from "../../../assets/app.config";
import configHeader from "../../../assets/utils/helperConfigHeader";
import InputLayout from "../../shared/InputLayout";
import Loader from "../../shared/Loader";

const Information = ({ user, setUser }) => {
  const inputs = {
    fullname: "",
    email: "",
    username: "",
    password: "",
  };
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    ...inputs,
    fullname: user?.fullname,
    email: user?.email,
    username: user?.username,
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataChange = {};
    let dataisChanged = false;
    const keys = Object.keys(inputs);
    for (let key of keys) {
      if (form[key] && form[key] !== user[key]) {
        dataChange[key] = form[key];
        dataisChanged = true;
      }
    }

    if (dataisChanged) {
      setLoading(true);
      await axios.put(
        `${BASE_URI}user/${user._id}`,
        { ...dataChange },
        configHeader()
      );
      setUser({ ...user, ...dataChange });
    }
    if (form.password) {
      await axios.put(
        `${BASE_URI}user/password/${user._id}`,
        { password: form.password },
        configHeader()
      );
      setForm({ ...form, password: "" });
    }

    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <form className='assignFolder--information' onSubmit={handleSubmit}>
      <div className='assignFolder--forms'>
        <p className='titleLeft'>
          Identifiant <span />
        </p>
        <InputLayout
          value={form["fullname"]}
          name='fullname'
          onChange={handleChange}
          type='text'
          label='nom & prénom'
          required={false}
        />
        <InputLayout
          value={form["email"]}
          name='email'
          onChange={handleChange}
          type='email'
          label='e-mail'
          required={false}
        />
        <button className='btn-edit'>Modifier</button>
      </div>
      <div className='assignFolder--forms'>
        <p className='titleLeft'>
          Modifier Mot de passe <span />
        </p>
        <InputLayout
          value={form["username"]}
          name='username'
          onChange={handleChange}
          type='text'
          label='identifiant'
          required={false}
        />
        <InputLayout
          value={form["password"]}
          name='password'
          onChange={handleChange}
          type='password'
          label='mot de passe'
          required={false}
        />
        <button className='btn-edit'>Modifier</button>
      </div>
    </form>
  );
};

export default Information;
