import { useEffect, useState } from "react";
import MainLayout from "../../shared/MainLayout";
import List from "./List";
import ModalAdd from "./ModalAdd";
import ModalDelete from "./ModalDelete";
import lock from "../../../assets/icons/lock.svg";
import { Link } from "react-router-dom";
import { findUser, getUsers } from "../../../assets/api/usersApi";
import { useCallback } from "react";
import Loader from "../../shared/Loader";
import { useHistory } from "react-router-dom";
import { helperDecriptToken } from "../../../assets/utils/helperAtobToken";
import WithCheckUser from "../../../hoc/withCheckUser";

const Management = () => {
  const token = helperDecriptToken(sessionStorage.getItem("transferLux-token"));
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const history = useHistory();
  const getDataUsers = useCallback(
    async function getData() {
      const { data } = await getUsers();
      setUsersList(data);
    },
    [getUsers]
  );

  useEffect(() => {
    getDataUsers();
  }, [getDataUsers]);

  useEffect(() => {
    if (token.scope !== "ROLE_ADMIN") {
      history.push("/dashboard");
    }
  }, [token.scope]);

  useEffect(async () => {
    async function getData() {
      setLoading(true);
      if (!!!search.trim()) {
        getDataUsers();
      }
      setLoading(false);
    }
    getData();
  }, [search]);

  const handleSearch = async () => {
    if (search.trim()) {
      setLoading(true);
      const { data } = await findUser(search);
      setUsersList(data.users);
      setLoading(false);
    }
  };

  return (
    <MainLayout
      linkLeft='/management'
      linkRight={`/dashboard?token=${sessionStorage.getItem(
        "transferLux-token"
      )}`}
      labelLinkLeft='Gestion'
      labelLinkRight='Mes Dossier'>
      <ModalAdd showModal={showModal} setShowModal={setShowModal} />
      <ModalDelete
        user={userSelected}
        setUsersList={setUsersList}
        showModal={showModalDelete}
        setShowModal={setShowModalDelete}
      />
      <div className='management'>
        <Link to='/informations' className='management--btn-viewInfo'>
          <img src={lock} alt='lock' /> Mes Informations
        </Link>
        <div className='search'>
          <input
            type='text'
            name='search'
            placeholder='Qui recherchez-vous ?'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={handleSearch}>
            <i className='fas fa-search' />
          </button>
        </div>
        <p className='titleLeft'>
          Gestion <span />
        </p>
        <button className='btnAdd' onClick={() => setShowModal(true)}>
          <i className='fas fa-plus' />
          Ajouter un utilisateur
        </button>
        {showDeleteBtn ? (
          <button
            className='btn_edit red'
            onClick={() => setShowDeleteBtn(!showDeleteBtn)}>
            Terminer
          </button>
        ) : (
          <button
            className='btn_edit'
            onClick={() => setShowDeleteBtn(!showDeleteBtn)}>
            Modifier
          </button>
        )}
        {loading && <Loader />}
        {!loading && (
          <List
            usersList={usersList}
            setUserSelected={setUserSelected}
            setShowModalDelete={setShowModalDelete}
            showDeleteBtn={showDeleteBtn}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default WithCheckUser(Management);
