import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { deleteUser } from "../../../assets/api/usersApi";

const ModalDelete = ({ showModal, setShowModal, setUsersList, user }) => {
  const [loading,setLoading] = useState(false);
  const handleDelete = async (id)=>{
    setLoading(true);
    const res = await deleteUser(id);
    if(res.status === 200){
      setUsersList((users)=>users.filter(user=>user._id !== id))
    }
    setLoading(false);
    setShowModal(false);
}

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      keyboard={false}
      centered
      size="200"
    >
      <Modal.Header>
        <span className="selectedFolder--modalTitle">
          EFFACER {` "${user?.fullname}"`}
        </span>{" "}
      </Modal.Header>
      <Modal.Body>
        <span className="selectedFolder--modaltext">
          Êtes vous sur de vouloir supprimer l'utilisateur ?
        </span>
        <div className="selectedFolder--btns-modal">
          <button
            className="selectedFolder--btn-annuler"
            onClick={() => setShowModal(false)}
          >
            Annuler
          </button>
          <button
            disabled={loading}
            onClick={() => handleDelete(user._id)}
            className="selectedFolder--btn-delete"
          >
            {loading?"...":"Effacer"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDelete;
