import logo from '../../../assets/images/logo.svg';
import user from '../../../assets/icons/user.svg';
import password from '../../../assets/icons/password.svg';
import eye from '../../../assets/icons/eye.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../../assets/app.config';
import { helperToast } from '../../../assets/utils/helperToast';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { helperProtocol } from '../../../assets/utils/helperProtocol';
const { version } = require('../../../../package.json');

const Login = () => {
  const history = useHistory();
  const inputs = {
    username: '',
    password: '',
  };
  const [form, setForm] = useState(inputs);
  const [showPassord, setShowPassord] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await axios
      .post(`${BASE_URI}user/auth`, {
        username: form.username,
        password: form.password,
      })
      .catch((err) => {
        helperToast(false, 'Error : username or password');
      });
    if (res?.status === 200) {
      helperToast(true, 'Success');
      sessionStorage.setItem('transferLux-token', res.data.token);
      history.push(`/dashboard?token=${res.data.token}`);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    helperProtocol();
  }, []);

  return (
    <div className='login'>
      <form className='login--form' onSubmit={handleSubmit}>
        <img src={logo} alt='logo' className='login--logo' />
        <div className='login--inputs'>
          <div className='login--inputs_container'>
            <label
              className='login--inputs_label'
              style={{ textTransform: 'inherit' }}
            >
              Identifiant
            </label>
            <span className='login--inputs_style'>
              <img src={user} alt='user' />
              <input
                type='text'
                onChange={handleChange}
                value={form['username']}
                name='username'
              />
            </span>
          </div>
          <div className='login--inputs_container'>
            <label
              className='login--inputs_label'
              style={{ textTransform: 'inherit' }}
            >
              Mot de passe
            </label>
            <span className='login--inputs_style'>
              <img src={password} alt='password' />
              <input
                type={showPassord ? 'text' : 'password'}
                onChange={handleChange}
                value={form['password']}
                name='password'
              />
              <img
                src={eye}
                alt='eye'
                onClick={() => setShowPassord(!showPassord)}
                className='login--inputs_showPassword'
              />
            </span>
          </div>
          <span
            className='login--inputs_forget-text'
            style={{ textTransform: 'inherit' }}
          >
            <Link to='/forget'>Mot de passe oublié ?</Link>
          </span>
        </div>
        <button disabled={loading} className='login--button'>
          {loading ? 'chargement' : 'connexion'}
        </button>
        <p className='version'>VERSION {version}</p>
      </form>
    </div>
  );
};

export default Login;
