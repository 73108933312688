import { HashRouter, Switch, Route } from "react-router-dom";
import Login from "./offline/login";
import Forget from "./offline/forget";
import Online from "./online";
import NotFound from "./NotFound";
import { Toaster } from "react-hot-toast";
import Reset from "./offline/reset";

function App() {
  return (
    <HashRouter>
      <Toaster position="top-right" reverseOrder={false} />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forget" component={Forget} />
        <Route exact path="/reset/:slug" component={Reset} />
        <Route path="/" component={Online} />
        <Route component={NotFound} />
      </Switch>
    </HashRouter>
  );
}

export default App;
