import toast from "react-hot-toast";
/**
 *
 * @param {Boolean} success Boolean
 * @param {String} message String
 */
export const helperToast = (
  success,
  message = "Message à définir en deuxième argument"
) => {
  if (success) {
    return toast.success(message);
  } else {
    return toast.error(message);
  }
};
