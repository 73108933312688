import { useEffect, useState } from 'react';
import SelectedLayout from '../../shared/SelectedLayout';
import AddFile from './AddFile';
import List from './List';
import { helperUriParser } from '../../../assets/utils/helperUri';
import { findFolderFile, getFolderById } from '../../../assets/api/foldersApi';
import WithCheckUser from '../../../hoc/withCheckUser';
import axios from 'axios';
import { BASE_URI } from '../../../assets/app.config';
import Loader from '../../shared/Loader';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import configHeader from '../../../assets/utils/helperConfigHeader';
import { helperDecriptToken } from '../../../assets/utils/helperAtobToken';
import { helperToast } from '../../../assets/utils/helperToast';
import JSZip from 'jszip';

const SelectedFolder = () => {
  const token = helperDecriptToken(sessionStorage.getItem('transferLux-token'));
  const id = helperUriParser();
  const [newFiles, setNewFiles] = useState([]);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showAddFile, setShowAddFile] = useState(false);
  const [folder, setFolder] = useState(null);
  const [filesSelected, setFilesSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMessage, setLoadMessage] = useState(null);

  const [search, setSearch] = useState('');
  const [percent, setPercent] = useState(0);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingDownloadFiles, setLoadingDownloadFiles] = useState(false);

  const history = useHistory();

  const getDataFolder = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getFolderById(id);
      setFolder(data);
    } catch (err) {
      history.push(
        `/dashboard?token=${sessionStorage.getItem('transferLux-token')}`
      );
    }
    setLoading(false);
  }, [id, getFolderById]);

  useEffect(() => {
    getDataFolder();
  }, [getDataFolder]);

  useEffect(() => {
    async function getData() {
      if (!!!search && id) {
        getDataFolder();
      }
    }
    getData();
  }, [search, id]);

  const handleSearch = async () => {
    if (search.trim()) {
      setLoading(true);
      const { data } = await findFolderFile(id, search);
      setFolder(data.folder);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   setLoading(true);
  //   async function getData() {
  //     const { data } = await getFolderById(id);
  //     setFolder(data);
  //   }
  //   setLoading(false);
  //   getData();
  // }, [setFolder]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingFile(true);
    setLoadMessage('Téléchargement...');
    const formData = new FormData();
    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      formData.append('file', file);
    }
    const res = await axios.post(`${BASE_URI}add-file`, formData, {
      ...configHeader(),
      onUploadProgress: (ProgressEvent) => {
        setPercent(
          Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
        );
      },
    });

    if (res.data.success) {
      const filesnames = res.data?.filesnames;
      setLoadMessage('Sauvegarde...');

      for (let i = 0; i < filesnames.length; i++) {
        const filename = filesnames[i];
        const file = newFiles[i];
        axios
          .post(
            `${BASE_URI}file/add`,
            {
              name: file.name,
              fileName: filename,
              size: file.size,
              type: file.type,
            },
            configHeader()
          )
          .then((res) => {
            setLoadMessage('Mise à jour du dossier...');
            axios
              .put(
                `${BASE_URI}folder/${id}`,
                { newFile: res.data._id },
                configHeader()
              )
              .then((_) => {
                setLoadingFile(false);
                setLoadMessage(null);
                if (i + 1 === filesnames.length) {
                  window.location.reload();
                }
              })
              .catch((_) => {
                setLoadingFile(false);
                setLoadMessage(null);
                helperToast(
                  false,
                  "Une erreur s'est produite... Veuillez réessayer !"
                );
                return;
              });
          })
          .catch((err) => {
            helperToast(
              false,
              'Veuillez changer le nom du fichier et recommencer'
            );
            setLoadingFile(false);
            setLoadMessage(null);
            return;
          });
      }
    } else {
      setLoadingFile(false);
      setLoadMessage(null);
      helperToast(false, "Une erreur s'est produite... Veuillez réessayer !");
    }
  };

  const handleSelectAllFiles = () => {
    if (filesSelected.length === folder?.files?.length) {
      setFilesSelected([]);
    } else {
      setFilesSelected(folder?.files);
    }
  };

  const onDownloadSelectedFiles = async () => {
    setLoadingDownloadFiles(true);
    const zip = new JSZip();
    const promises = [];

    // Ajouter chaque fichier à l'archive
    filesSelected.forEach((file) => {
      const promise = axios.get(`${BASE_URI}file/download/${file.fileName}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded;
          const percentage = Math.round((loaded * 100) / file?.size);
          setPercent(percentage);
          // Mettre à jour le pourcentage de chargement pour ce fichier
        },
      });
      promises.push(promise);
      promise.then((response) => {
        zip.file(file.fileName, response.data);
      });
      setPercent(0);
    });

    // Attendre la fin de tous les téléchargements avant de générer l'archive
    await Promise.all(promises);

    // Générer l'archive
    zip
      .generateAsync({ type: 'blob' })
      .then((blob) => {
        // Créer un objet URL pour l'archive
        const url = window.URL.createObjectURL(blob);

        // Créer un lien de téléchargement et cliquer dessus pour lancer le téléchargement
        const link = document.createElement('a');
        link.href = url;
        link.download = `${Date.now()}-archive.zip`;
        link.click();

        // Libérer l'objet URL
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        setLoadingDownloadFiles(false);
      });
    setFilesSelected([]);
    setLoadingDownloadFiles(false);
  };

  return (
    <SelectedLayout
      setSearch={setSearch}
      search={search}
      handleSearch={handleSearch}
      title={folder?.name}
    >
      <div className='selectedFolder'>
        {token.scope === 'ROLE_ADMIN' && (
          <>
            <button
              className='selectedFolder--btn-download'
              onClick={() => setShowAddFile(!showAddFile)}
            >
              <i className='fas fa-plus' />
              Ajouter un fichier
            </button>
            {showAddFile && (
              <AddFile
                handleSubmit={handleSubmit}
                newFiles={newFiles}
                setNewFiles={setNewFiles}
                loading={loadingFile}
                loadMessage={loadMessage}
                percent={percent}
              />
            )}
          </>
        )}
        <div className='selectedFolder--list-header'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              className='btn_edit'
              onClick={handleSelectAllFiles}
              style={{ marginLeft: 'inherit' }}
            >
              {folder?.files?.length === filesSelected?.length
                ? 'Tout décocher'
                : 'Tout cocher'}
            </button>
            {filesSelected.length > 0 && (
              <button
                className='selectedFolder--downloadAllBtn'
                onClick={onDownloadSelectedFiles}
              >
                {loadingDownloadFiles ? 'Téléchargement...' : 'Télécharger'}
                {loadingDownloadFiles ? `${percent}%` : ''}
              </button>
            )}
          </div>
          {showDeleteBtn ? (
            <button
              className='btn_edit red'
              style={{ marginLeft: 'inherit' }}
              onClick={() => setShowDeleteBtn(!showDeleteBtn)}
            >
              Terminer
            </button>
          ) : (
            <button
              style={{ marginLeft: 'inherit' }}
              className='btn_edit'
              onClick={() => setShowDeleteBtn(!showDeleteBtn)}
            >
              Modifier
            </button>
          )}
        </div>
        {loading && <Loader />}
        {!loading && (
          <List
            folder={folder?.files}
            showDeleteBtn={showDeleteBtn}
            filesSelected={filesSelected}
            setFilesSelected={setFilesSelected}
          />
        )}
      </div>
    </SelectedLayout>
  );
};

export default WithCheckUser(SelectedFolder);
