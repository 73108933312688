import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import picture from '../../../assets/images/picture.svg';
import { File } from '../../shared/File';

const AddFile = ({
  setNewFiles,
  newFiles,
  loading = true,
  loadMessage,
  handleSubmit,
  percent,
}) => {
  //
  const handleDeleteFile = async (id) => {
    setNewFiles((files) => files.filter((_, ind) => ind !== id));
  };

  return (
    <>
      <div className='selectedFolder--files-container'>
        <UploadFile newFiles={newFiles} setNewFiles={setNewFiles} />
        {newFiles?.map((file, index) => (
          <File
            key={index}
            file={file}
            handleDelete={() => handleDeleteFile(index)}
          />
        ))}
      </div>
      {newFiles?.length > 0 && (
        <button
          onClick={handleSubmit}
          disabled={loading}
          className='selectedFolder--btn-download'
        >
          {loading && (
            <div
              class='spinner-border spinner-border-sm text-light mr-2'
              role='status'
            >
              <span class='visually-hidden'></span>
            </div>
          )}
          {loading
            ? `${percent < 100 ? percent + '%' : ''} ${loadMessage}`
            : 'Télécharger'}
        </button>
      )}
    </>
  );
};

const UploadFile = ({ newFiles, setNewFiles }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setNewFiles((currentState) => [...currentState, ...acceptedFiles]);
    },
    [setNewFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div {...getRootProps()} className='selectedFolder--dropzone'>
      <input {...getInputProps()} />

      {!!!isDragActive && (
        <div className='selectedFolder--preview-container'>
          <p>
            Déposez le{`(s)`} fichier{`(s)`}
          </p>
          <img src={picture} alt='pictures' />
          <span className='selectedFolder--dropzone-text1'>
            <span>Télécharger</span> ou en Cliquant ici
          </span>
          <span className='selectedFolder--dropzone-text2'>
            PDF, JPG, .DBF, .CSV, .ZIP... <br />
          </span>
        </div>
      )}
      {isDragActive && (
        <p>
          Déposez le{`(s)`} fichier{`(s)`} ici...
        </p>
      )}
    </div>
  );
};

export default AddFile;
