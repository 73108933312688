const InputLayout = ({
  label,
  type,
  name,
  value,
  onChange,
  required = true,
}) => (
  <div className="inputLayout">
    <label>{label}</label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
    />
  </div>
);

export default InputLayout;
