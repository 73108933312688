import { useEffect, useState } from 'react';
import ModalAdd from './ModalAdd';
import ModalDelete from './ModalDelete';
import MainLayout from '../../shared/MainLayout';
import { Link } from 'react-router-dom';
import { helperDecriptToken } from '../../../assets/utils/helperAtobToken';
import { findFolder, getFolders } from '../../../assets/api/foldersApi';
import WithCheckUser from '../../../hoc/withCheckUser';
import { useCallback } from 'react';
import Loader from '../../shared/Loader';
import { findUserFolder, getUserById } from '../../../assets/api/usersApi';

const Dashboard = () => {
  const token = helperDecriptToken(sessionStorage.getItem('transferLux-token'));

  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelte] = useState(false);
  const [search, setSearch] = useState('');
  const [folders, setFolders] = useState([]);
  const [folderSelected, setFolderSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDataFolder = useCallback(async () => {
    const res = await (token.scope === 'ROLE_ADMIN'
      ? getFolders()
      : getUserById(token.res.id));
    if (token.scope === 'ROLE_ADMIN') {
      setFolders(res.data);
    } else {
      setFolders(res.data.user.folders);
    }
  }, [token.id, getFolders]);

  useEffect(() => {
    getDataFolder();
  }, [getDataFolder]);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      if (!!!search) {
        getDataFolder();
      }
      setLoading(false);
    }
    getData();
  }, [search]);

  const handleSearch = async () => {
    if (search.trim()) {
      setLoading(true);
      const { data } = await (token.scope === 'ROLE_ADMIN'
        ? findFolder
        : findUserFolder)(search);

      setFolders(data.folders);
      setLoading(false);
    }
  };

  //DELETED FOLDER
  const handleDelete = (folder) => {
    setShowModalDelte(true);
    setFolderSelected(folder);
  };

  return (
    <MainLayout
      padding={0}
      linkLeft={`/dashboard?token=${sessionStorage.getItem(
        'transferLux-token'
      )}`}
      linkRight={token.scope === 'ROLE_ADMIN' ? '/management' : null}
      labelLinkLeft='Mes dossiers'
      labelLinkRight={token.scope === 'ROLE_ADMIN' ? 'Gestion' : null}
    >
      {token.scope === 'ROLE_ADMIN' && (
        <>
          <ModalAdd showModal={showModal} setShowModal={setShowModal} />
          <ModalDelete
            showModal={showModalDelete}
            folder={folderSelected}
            setShowModal={setShowModalDelte}
          />
        </>
      )}
      <div className='dashboard'>
        <div className='search'>
          <input
            type='text'
            name='search'
            placeholder='Quel dossier cherches tu ?'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={handleSearch}>
            <i className='fas fa-search' />
          </button>
        </div>
        <p className='titleLeft'>
          Mes dossiers <span />
        </p>
        {token.scope === 'ROLE_ADMIN' &&
          (showDeleteBtn ? (
            <button
              className='btn_edit red'
              onClick={() => setShowDeleteBtn(!showDeleteBtn)}
            >
              Terminer
            </button>
          ) : (
            <button
              className='btn_edit'
              onClick={() => setShowDeleteBtn(!showDeleteBtn)}
            >
              Modifier
            </button>
          ))}
        <div className='dashboard--folder_container'>
          {token.scope === 'ROLE_ADMIN' && (
            <button
              className='dashboard--folder_btnAdd'
              onClick={() => setShowModal(true)}
            >
              <i className='fas fa-plus dashboard--folder_icon' />
              <span
                className='dashboard--folder_btnLabel'
                style={{ textAlign: 'center' }}
              >
                Ajouter un dossier
              </span>
            </button>
          )}
          {loading && <Loader />}
          {!loading &&
            folders.map((item, id) =>
              !showDeleteBtn ? (
                <Link to={`/selected/${item._id}`} key={id}>
                  <button className='dashboard--otherFolder_btn'>
                    <span className='dashboard--folder_btnLabel'>
                      {item.name}
                    </span>
                    <span className='dashboard--folder_btnLabel2'>
                      {item.files.length} File
                      {`${item.files.length > 1 ? 's' : ''}`}
                    </span>
                  </button>
                </Link>
              ) : (
                <button className='dashboard--otherFolder_btn' key={id}>
                  {showDeleteBtn && (
                    <span
                      className='dashboard--otherFolder_btnDelete'
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item);
                      }}
                    >
                      <i className='fas fa-minus' />
                    </span>
                  )}
                  <span className='dashboard--folder_btnLabel'>
                    {item.name}
                  </span>
                  <span className='dashboard--folder_btnLabel2'>
                    {item.files.length} File
                    {`${item.files.length > 1 ? 's' : ''}`}
                  </span>
                </button>
              )
            )}
        </div>
      </div>
    </MainLayout>
  );
};

export default WithCheckUser(Dashboard);
