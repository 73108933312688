import { useHistory } from "react-router-dom";

//Icons
import Arrow from "../../../assets/icons/arrow-left.svg";
const SelectedLayout = (props) => {
  const navigation = useHistory();
  const { search, setSearch, handleSearch } = props;
  return (
    <div className='selectedLayout'>
      <div className='selectedLayout--header'>
        <button className='return-btn' onClick={() => navigation.goBack()}>
          <img src={Arrow} alt='arrow' /> Retour
        </button>
        <div className='search'>
          <input
            type='text'
            name='search'
            placeholder='Quel dossier cherches tu ?'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={handleSearch}>
            <i className='fas fa-search' />
          </button>
        </div>
        <p className='titleLeft'>
          {props.title} <span />
        </p>
      </div>
      <div className='selectedLayout--container'>{props.children}</div>
    </div>
  );
};

export default SelectedLayout;
