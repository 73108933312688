import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { useHistory } from "react-router-dom";
const Logout = () => {
  const history = useHistory();
  const handleLogout = () => {
    sessionStorage.clear();
    history.push("/");
  };
  return (
    <div onClick={handleLogout} className="logout">
      <PowerSettingsNewIcon />
    </div>
  );
};

export default Logout;
