import { useState } from 'react';
import { Link } from 'react-router-dom';

//ICONS & IMAGES
import logo from '../../../assets/images/logo.svg';
import user from '../../../assets/icons/user.svg';
import axios from 'axios';
import { BASE_URI } from '../../../assets/app.config';
import { helperToast } from '../../../assets/utils/helperToast';
const { version } = require('../../../../package.json');

const Forget = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_URI}user/send-email-reset-password`,
        { email: email }
      );
      if (res.status === 200) {
        helperToast(true, 'Email envoyé !');
      }
    } catch (err) {
      setLoading(false);
      helperToast(false, 'Une erreur est survenue !');
    }

    setLoading(false);
  };

  return (
    <div className='login'>
      <form className='login--form' onSubmit={handleSubmit}>
        <img src={logo} alt='logo' className='login--logo' />
        <div className='login--inputs'>
          <div className='login--inputs_container'>
            <label
              className='login--inputs_label'
              style={{ textTransform: 'inherit' }}
            >
              Identifiant
            </label>
            <span className='login--inputs_style'>
              <img src={user} alt='user' />
              <input
                type='text'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                name='username'
              />
            </span>
          </div>

          <span
            className='login--inputs_forget-text'
            style={{ textTransform: 'inherit' }}
          >
            <Link to='/'>Revenir à la connexion</Link>
          </span>
        </div>
        <button
          disabled={loading}
          className='login--button'
          onClick={(e) => handleSubmit(e)}
        >
          {loading ? 'Envoyer...' : 'Envoyer'}
        </button>
        <p className='version'>VERSION {version}</p>
      </form>
    </div>
  );
};

export default Forget;
