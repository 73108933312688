//icons
import { Link } from "react-router-dom";
import edit from "../../../assets/icons/edit.svg";

const List = ({
  usersList,
  showDeleteBtn,
  setShowModalDelete,
  setUserSelected,
}) => {
  return (
    <ul className='management--list-container'>
      {usersList.map((list, id) => (
        <li className='management--list' key={id}>
          <span className='management--list-name'>{list.fullname}</span>
          {showDeleteBtn ? (
            <span
              className='management--btnDelete'
              onClick={() => {
                setUserSelected(list);
                setShowModalDelete(true);
              }}>
              <i className='fas fa-minus' />
            </span>
          ) : (
            <span className='management--btnEdit'>
              <Link to={`/assign-folder/${list._id}`}>
                <img src={edit} alt='cloud' className='management--list-icon' />
              </Link>
            </span>
          )}
        </li>
      ))}
    </ul>
  );
};

export default List;
