import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//Icons
import Arrow from "../../../assets/icons/arrow-left.svg";
import FolderList from "./FolderList";
import Information from "./Information";
import { helperDecriptToken } from "../../../assets/utils/helperAtobToken";
import { findFolder, getFolders } from "../../../assets/api/foldersApi";
import { useCallback } from "react";
import { getUserById } from "../../../assets/api/usersApi";
import { helperUriParser } from "../../../assets/utils/helperUri";
import WithCheckUser from "../../../hoc/withCheckUser";

const AssignFolder = () => {
  const token = helperDecriptToken(sessionStorage.getItem("transferLux-token"));
  const navigation = useHistory();
  const [toggle, setToggle] = useState("folder");
  const [folders, setFolders] = useState([]);
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const history = useHistory();

  const getDataFolder = useCallback(async () => {
    setLoading(true);
    const { data } = await getFolders();
    setFolders(data);
    setLoading(false);
  }, [getFolders]);

  useEffect(() => {
    getDataFolder();
  }, [getDataFolder]);

  useEffect(() => {
    if (token.scope !== "ROLE_ADMIN") {
      history.push(
        `/dashboard?token=${sessionStorage.getItem("transferLux-token")}`
      );
    }
  }, [token.scope]);

  useEffect(async () => {
    async function getData() {
      if (!!!search) {
        getDataFolder();
      }
    }
    getData();
  }, [search]);

  useEffect(() => {
    async function getData() {
      const { data } = await getUserById(helperUriParser());
      setUser(data.user);
    }
    getData();
  }, []);

  const handleSearch = async () => {
    if (search.trim()) {
      setLoading(true);
      const { data } = await findFolder(search);
      setFolders(data.folders);
      setLoading(false);
    }
  };

  return (
    <div className='assignFolder'>
      <div className='assignFolder--header'>
        <button className='return-btn' onClick={() => navigation.goBack()}>
          <img src={Arrow} alt='arrow' /> Retour
        </button>
        <div className='assignFolder--toggle-container'>
          <button
            className={`assignFolder--toggle-btn ${
              toggle === "folder" && "assignFolder--toggle-btn-active"
            }`}
            onClick={() => setToggle("folder")}>
            Dossier de {` ${user?.fullname}`}
          </button>
          <button
            className={`assignFolder--toggle-btn ${
              toggle === "information" && "assignFolder--toggle-btn-active"
            }`}
            onClick={() => setToggle("information")}>
            Information de {` ${user?.fullname}`}
          </button>
        </div>
      </div>
      <div className='assignFolder--container'>
        {toggle === "folder" && (
          <FolderList
            search={search}
            user={user}
            setSearch={setSearch}
            handleSearch={handleSearch}
            setLoading={setLoading}
            loading={loading}
            folders={folders}
            setUser={setUser}
          />
        )}
        {toggle === "information" && (
          <Information user={user} setUser={setUser} />
        )}
      </div>
    </div>
  );
};

export default WithCheckUser(AssignFolder);
