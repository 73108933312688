import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { deleteFolder } from "../../../assets/api/foldersApi";
import { helperToast } from "../../../assets/utils/helperToast";

const ModalDelete = ({ showModal, setShowModal, folder }) => {
  const [loading,setLoading] = useState(false);
  const handlePullFolder = async (id)=>{
    setLoading(true);
    try{
      const res = await deleteFolder(id);
      if (res?.status === 200) {
        helperToast(true, "Success");
        setShowModal(false);
        window.location.reload();
      } else {
        helperToast(false, "Error");
      }
    }catch(err){
      helperToast(false, "Error");
    }finally{
      
    setLoading(false);
    }
   
  }
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      keyboard={false}
      centered
      size="200"
    >
      <Modal.Header>
        <span className="selectedFolder--modalTitle">
          EFFACER {` "${folder?.name}"`}
        </span>{" "}
      </Modal.Header>
      <Modal.Body>
        <span className="selectedFolder--modaltext">
          Êtes vous sur de vouloir effacer ?
        </span>
        <div className="selectedFolder--btns-modal">
          <button
            className="selectedFolder--btn-annuler"
            onClick={() => setShowModal(false)}
          >
            Annuler
          </button>
          <button
            disabled={loading}
            onClick={() => handlePullFolder(folder._id)}
            className="selectedFolder--btn-delete"
          >
            {loading?"Effacer...":"Effacer"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDelete;
