import { useState } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../../assets/app.config';
import { helperUriParser } from '../../../assets/utils/helperUri';
import { useHistory } from 'react-router-dom';
import { helperToast } from '../../../assets/utils/helperToast';
//ICONS & IMAGES
import logo from '../../../assets/images/logo.svg';
import eyeIcon from '../../../assets/icons/eye.svg';
import passwordIcon from '../../../assets/icons/password.svg';
const { version } = require('../../../../package.json');

const Reset = () => {
  const token = helperUriParser(1);
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [showPassord, setShowPassord] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.put(`${BASE_URI}user/reset-password/${token}`, {
        password,
      });
      if (res.status === 200) {
        helperToast(true, 'Mot de passe modifié !');
        setTimeout(() => {
          history.push('/');
        }, 1500);
      }
    } catch (err) {
      setLoading(false);
      helperToast(false, "Une erreur s'est produite !");
    }
    setLoading(false);
  };

  return (
    <div className='login'>
      <form className='login--form'>
        <img src={logo} alt='logo' className='login--logo' />
        <div className='login--inputs'>
          <div className='login--inputs_container'>
            <label
              className='login--inputs_label'
              style={{ textTransform: 'inherit' }}
            >
              Mot de passe
            </label>
            <span className='login--inputs_style'>
              <img src={passwordIcon} alt='password' />
              <input
                type={showPassord ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                name='password'
              />
              <img
                src={eyeIcon}
                alt='eye'
                onClick={() => setShowPassord(!showPassord)}
                className='login--inputs_showPassword'
              />
            </span>
          </div>
        </div>
        <button
          disabled={loading}
          className='login--button'
          onClick={(e) => handleSubmit(e)}
        >
          {loading ? 'Modification...' : 'Modifier'}
        </button>
        <p className='version'>VERSION {version}</p>
      </form>
    </div>
  );
};

export default Reset;
