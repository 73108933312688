/**
 *
 * @param {number} index select index if index is undefined his function return an array
 * @returns
 */
export const helperUriParser = (index) => {
  return document.location.hash.split("/")[
    document.location.hash.split("/").length - 1
  ];
};
