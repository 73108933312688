import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getUserById } from "../../../assets/api/usersApi";
import { BASE_URI } from "../../../assets/app.config";

//Icons
import Arrow from "../../../assets/icons/arrow-left.svg";
import eyeBlue from "../../../assets/icons/eye-blue.svg";
import eyeBlue2 from "../../../assets/icons/eye-blue2.svg";
import { helperDecriptToken } from "../../../assets/utils/helperAtobToken";
import configHeader from "../../../assets/utils/helperConfigHeader";
import WithCheckUser from "../../../hoc/withCheckUser";
import Loader from "../../shared/Loader";

const InputLayout = ({
  placeholder,
  type,
  name,
  value,
  onChange,
  required = false,
  showIcon = false,
  icon,
  setShowPassword,
}) => (
  <label className='infos--inputLayout'>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      placeholder={placeholder}
    />
    {showIcon && (
      <img src={icon} alt={icon} onClick={() => setShowPassword((x) => !x)} />
    )}
  </label>
);

const Informations = () => {
  const inputs = {
    username: "",
    password: "",
    passwordConfirm: "",
  };
  const token = helperDecriptToken(
    sessionStorage.getItem("transferLux-token")
  ).res;
  const navigation = useHistory();
  const [form, setForm] = useState(inputs);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirme, setShowPasswordConfirme] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState({});
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const { data } = await getUserById(token.id);
      setForm({ ...form, username: data.user.username });
      setUser({ username: data.user.username });
      setLoading(false);
    }
    if (token.id !== undefined) {
      getData();
    }
  }, [token.id]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (form.password && form.password !== form.passwordConfirm) {
      setError("les deux mots de passe ne sont pas identique");
      return;
    }
    setLoading(true);
    if (form.username !== user.username) {
      await axios.put(
        `${BASE_URI}user/${token.id}`,
        { username: form.username },
        configHeader()
      );
      setUser({ ...user, username: form.username });
    }

    if (form.password) {
      await axios.put(
        `${BASE_URI}user/password/${token.id}`,
        { password: form.passwordConfirm },
        configHeader()
      );
      setForm({ ...form, password: "", passwordConfirm: "" });
    }

    setLoading(false);
  };

  return (
    <div className='infos'>
      <div className='infos--header'>
        <button className='return-btn' onClick={() => navigation.goBack()}>
          <img src={Arrow} alt='arrow' /> Retour
        </button>
      </div>
      {loading && <Loader />}
      {!loading && (
        <>
          <p className='infos--title'>mes informations</p>
          <form className='infos--form' onSubmit={handleSubmit}>
            <div className='infos--username'>
              <p className='infos--title-form'>
                Identifiant
                <span />
              </p>

              <InputLayout
                placeholder='identifiant'
                name='username'
                value={form["username"]}
                onChange={handleChange}
                type='text'
              />
              <span className='infos--btn-edit'>Modifier</span>
            </div>
            <div className='infos--username'>
              <p className='infos--title-form'>
                Modifier mot de passe
                <span />
              </p>

              <InputLayout
                placeholder='mot de passe'
                name='password'
                value={form["password"]}
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
                showIcon={true}
                icon={showPassword ? eyeBlue : eyeBlue2}
                setShowPassword={setShowPassword}
              />
              <InputLayout
                placeholder='confirmé mot de passe'
                name='passwordConfirm'
                value={form["passwordConfirm"]}
                onChange={handleChange}
                type={showPasswordConfirme ? "text" : "password"}
                showIcon={true}
                icon={showPasswordConfirme ? eyeBlue : eyeBlue2}
                setShowPassword={setShowPasswordConfirme}
              />
              <button className='btn-edit'>Modifier</button>
              {error && <p>{error}</p>}
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default WithCheckUser(Informations);
