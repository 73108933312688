//Image
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-2.svg';
import Logout from '../Logout';
const MainLayout = (props) => {
  return (
    <div className='mainLayout'>
      <div className='mainLayout--header'>
        <Link to={props.linkLeft}>
          <button className='mainLayout--button_link mainLayout--link-active'>
            {props.labelLinkLeft}
          </button>
        </Link>
        <img src={logo} alt='logo' className='mainLayout--logo' />

        {props.linkRight && (
          <Link to={props.linkRight}>
            <button
              style={{ opacity: props.linkRight ? 1 : 0 }}
              className='mainLayout--button_link '
            >
              {props.labelLinkRight}
            </button>
          </Link>
        )}
      </div>
      <div className='mainLayout--container'>{props.children}</div>
      <Logout />
    </div>
  );
};

export default MainLayout;
