import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { addFolder } from '../../../assets/api/foldersApi';
import { helperToast } from '../../../assets/utils/helperToast';

const ModalAdd = ({ showModal, setShowModal }) => {
  const inputs = {
    name: '',
  };
  const [form, setForm] = useState(inputs);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await addFolder(form);
    if (res?.status === 200) {
      helperToast(true, 'Success');
      setShowModal(false);
      document.location.reload();
    } else {
      helperToast(false, 'Error');
    }
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      keyboard={false}
      centered
    >
      <Modal.Header>
        <span className='modal-title'>Ajouter un dossier</span>
      </Modal.Header>
      <Modal.Body>
        <div className='dashboard--inputLayout'>
          <label>Nom du dossier</label>
          <input
            type='text'
            name='name'
            value={form['name']}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            required
          />
        </div>
        <div className='dashboard--btns-modal'>
          <span
            className='dashboard--btn-annuler'
            onClick={() => setShowModal(false)}
          >
            Annuler
          </span>
          <button className='dashboard--btn-adduser' onClick={handleSubmit}>
            Ajouter
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAdd;
