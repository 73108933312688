import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { addUser } from '../../../assets/api/usersApi';

const InputLayout = ({
  label,
  type,
  name,
  value,
  onChange,
  required = true,
}) => (
  <div className='management--inputLayout'>
    <label>{label}</label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
    />
  </div>
);

const ModalAdd = ({ showModal, setShowModal }) => {
  const inputs = {
    fullname: '',
    email: '',
    username: '',
    password: '',
  };
  const [form, setForm] = useState(inputs);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await addUser({
      fullname: form.fullname,
      email: form.email,
      username: form.username,
      password: form.password,
    }).catch((err) => {
      return;
    });
    setLoading(false);
    if (res?.status === 200) {
      window.location.reload();
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      keyboard={false}
      centered
    >
      <Modal.Header>
        <span className='modal-title'>Ajouter un utilisateur</span>
      </Modal.Header>
      <Modal.Body>
        <form className='management--form' onSubmit={handleSubmit}>
          <InputLayout
            value={form['fullname']}
            name='fullname'
            onChange={handleChange}
            type='text'
            label='nom & prénom'
          />
          <InputLayout
            value={form['email']}
            name='email'
            onChange={handleChange}
            type='email'
            label='e-mail'
          />
          <InputLayout
            value={form['username']}
            name='username'
            onChange={handleChange}
            type='text'
            label='Identifiant'
          />
          <InputLayout
            value={form['password']}
            name='password'
            onChange={handleChange}
            type='password'
            label='mot de passe'
          />
          <div className='management--btns-modal'>
            <span
              className='management--btn-annuler'
              onClick={() => setShowModal(false)}
            >
              Annuler
            </span>
            <button className='management--btn-adduser' disabled={loading}>
              {loading ? 'Ajouter...' : 'Ajouter'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAdd;
