import { useEffect } from "react";
import { useState } from "react";
import { addFolder, removeFolder } from "../../../assets/api/usersApi";
import folderMini from "../../../assets/icons/folderMini.svg";
import Loader from "../../shared/Loader";

const FolderList = ({
  folders,
  search,
  setSearch,
  loading,
  setLoading,
  handleSearch,
  setUser,
  user,
}) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (user) setFilters(user.folders.map((el) => el._id));
  }, [user]);

  const handleSelect = async (value) => {
    const folders = user.folders;
    if (!filters.includes(value._id)) {
      setLoading(true);
      await addFolder(user._id, value._id);
      setFilters([...filters, value._id]);
      setUser({ ...user, folders: [...folders, value] });
      setLoading(false);
    } else {
      setLoading(true);
      await removeFolder(user._id, value._id);
      setFilters(filters.filter((el) => el !== value._id));
      const newFolders = folders.filter((folder) => folder._id !== value._id);
      setUser({ ...user, folders: [...newFolders] });
      setLoading(false);
    }
  };
  return (
    <>
      <div className='search'>
        <input
          type='text'
          name='search'
          placeholder='Qui recherchez-vous ?'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button onClick={handleSearch}>
          <i className='fas fa-search' />
        </button>
      </div>
      <p className='assignFolder--title'>Attribuer des dossiers</p>
      <div className='assignFolder--folderList-container'>
        <div className='assignFolder--folderList-header'>
          <select name='filter'>
            <option value='all'>Tout</option>
            <option value=''></option>
            <option value=''></option>
          </select>
        </div>
        {loading && <Loader />}
        {!loading &&
          folders.map((folder, id) => (
            <button
              className='assignFolder--folderList'
              key={id}
              onClick={() => handleSelect(folder)}>
              <span
                className={`assignFolder--folderList-badge ${
                  filters.includes(folder._id) &&
                  "assignFolder--folderList-badge-active"
                }`}
              />
              <img src={folderMini} alt='folder' />
              {folder.name}
            </button>
          ))}
      </div>
    </>
  );
};

export default FolderList;
