import axios from "axios";
import { BASE_URI } from "../app.config";
import configHeader from "../utils/helperConfigHeader";

export const addUser = (body) => {
  return axios
    .post(`${BASE_URI}user/add`, body,
    configHeader())
    .catch((err) => console.log(err));
};

export const getUsers = () => {
  return axios.get(`${BASE_URI}users`,configHeader());
};

export const getUserById = (id) => {
  return axios.get(`${BASE_URI}user/by-id/${id}`,configHeader());
};

export const findUser = (search)=>{
  return axios.get(`${BASE_URI}users/search/${search}`,configHeader())
}

export const addFolder = (id,folderId)=>{
  return axios.post(`${BASE_URI}user/addfolder/${id}`,{folderId},configHeader())
}

export const removeFolder = (id,folderId)=>{
  return axios.put(`${BASE_URI}user/removefolder/${id}`,{folderId},configHeader())
}

export const findUserFolder = (search)=>{
  return axios.get(`${BASE_URI}user/folder/${search}`,configHeader())
}

export const deleteUser = (id)=>{
  return axios.delete(`${BASE_URI}user/${id}`,configHeader())
}
