import { useState } from 'react';
import ModalDelete from './ModalDelete';
//icons
import cloud from '../../../assets/icons/cloud.svg';
import { helperDate } from '../../../assets/utils/helperDate';
import { BASE_URI } from '../../../assets/app.config';
import axios from 'axios';

const List = ({
  showDeleteBtn,
  folder,
  handlePullFile,
  filesSelected,
  setFilesSelected,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);

  return (
    <>
      <ModalDelete
        file={file}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <ul className='selectedFolder--list-container'>
        {folder?.map((file, id) => (
          <File
            key={id}
            file={file}
            setFile={setFile}
            filesSelected={filesSelected}
            setFilesSelected={setFilesSelected}
            setShowModal={setShowModal}
            showDeleteBtn={showDeleteBtn}
          />
        ))}
      </ul>
    </>
  );
};

const File = ({
  file,
  setShowModal,
  setFile,
  showDeleteBtn,
  filesSelected,
  setFilesSelected,
}) => {
  //
  const findFile = filesSelected?.find((_f) => _f._id === file._id);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleDelete = (file) => {
    setShowModal(true);
    setFile(file);
  };

  const handleDownload = async (file) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URI}file/download/${file.fileName}`,
        {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const loaded = progressEvent.loaded;
            const percentage = Math.round((loaded * 100) / file?.size);
            setProgress(percentage);
          },
        }
      );

      // Créez un objet URL pour le fichier téléchargé
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
    setLoading(false);
  };

  const onSelectFile = () => {
    if (!!findFile) {
      setFilesSelected((currentState) =>
        currentState.filter((_f) => _f._id !== findFile._id)
      );
    } else {
      setFilesSelected((currentState) => [...currentState, file]);
    }
  };

  return (
    <>
      <li className='selectedFolder--list'>
        {!showDeleteBtn && (
          <span
            className={`selectedFolder--list-check ${
              !!findFile ? 'selectedFolder--list-checked' : ''
            }`}
            onClick={onSelectFile}
          />
        )}

        <span className='selectedFolder--list-name'>{file.name}</span>
        <span className='selectedFolder--list-date'>
          {helperDate(file.createdAt)}
        </span>
        {showDeleteBtn ? (
          <span
            className='selectedFolder--btnDelete'
            onClick={() => handleDelete(file)}
          >
            <i className='fas fa-minus' />
          </span>
        ) : (
          !!!findFile && (
            <button
              className='selectedFolder--list-btnDownload'
              onClick={() => handleDownload(file)}
            >
              {loading ? (
                <>
                  <div
                    class='spinner-border spinner-border-sm text-light mr-2'
                    role='status'
                  >
                    <span class='visually-hidden'></span>
                  </div>
                  <span>{progress + '%'}</span>
                </>
              ) : (
                <img
                  src={cloud}
                  alt='cloud'
                  className='selectedFolder--list-icon'
                />
              )}
            </button>
          )
        )}
      </li>
      {loading && (
        <progress style={{ width: '100%' }} value={progress} max='100' />
      )}
    </>
  );
};

export default List;
