import axios from "axios";
import { BASE_URI } from "../assets/app.config.js";
import { helperProtocol } from "../assets/utils/helperProtocol.js";

const WithCheckUser = (WrappedComponent) => (props) => {
  helperProtocol();
  const response = async function fetchSecure() {
    const res = await axios
      .get(
        `${BASE_URI}check-auth-user/${sessionStorage.getItem(
          "transferLux-token"
        )}`
      )
      .catch((err) => window.location.assign("/"));
    if (res?.status === 200) {
      return res;
    } else {
      window.location.assign("/");
    }
  };
  response();
  return <WrappedComponent {...props} />;
};

export default WithCheckUser;
